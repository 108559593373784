'use strict'
const imageClientApi = require('../api/uri/index')
const constants = require('../helpers/imageServiceConstants')

const wixStatic = 'https://static.wixstatic.com/'
const wixStaticWithMedia = 'https://static.wixstatic.com/media/'
const HAS_MEDIA_PREFIX_RE = /^media\//i

const devicePixelRatio = typeof window !== 'undefined' ? window.devicePixelRatio : 1

const getWixStaticURL = uri => HAS_MEDIA_PREFIX_RE.test(uri) ? `${wixStatic}${uri}` : `${wixStaticWithMedia}${uri}`

const getURL = (uri, {baseHostURL} = {}) => baseHostURL ? `${baseHostURL}${uri}` : getWixStaticURL(uri)


function getScaleToFitImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options) {
    const data = imageClientApi.getData(constants.fittingTypes.SCALE_TO_FIT, {
        id: relativeUrl,
        width: sourceWidth,
        height: sourceHeight,
        name: options && options.name
    }, {
        width: targetWidth,
        height: targetHeight,
        htmlTag: imageClientApi.htmlTag.IMG,
        alignment: imageClientApi.alignTypes.CENTER,
        pixelAspectRatio: devicePixelRatio
    }, options)

    return getURL(data.uri, options)
}

function getScaleToFillImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options) {
    const data = imageClientApi.getData(constants.fittingTypes.SCALE_TO_FILL, {
        id: relativeUrl,
        width: sourceWidth,
        height: sourceHeight,
        name: options && options.name,
        focalPoint: {
            x: options && options.focalPoint && options.focalPoint.x,
            y: options && options.focalPoint && options.focalPoint.y
        }
    }, {
        width: targetWidth,
        height: targetHeight,
        htmlTag: imageClientApi.htmlTag.IMG,
        alignment: imageClientApi.alignTypes.CENTER,
        pixelAspectRatio: devicePixelRatio
    }, options)

    return getURL(data.uri, options)
}

function getCropImageURL(relativeUrl, sourceWidth, sourceHeight, cropX, cropY, cropWidth, cropHeight, targetWidth, targetHeight, options) {
    const data = imageClientApi.getData(constants.fittingTypes.SCALE_TO_FILL, {
        id: relativeUrl,
        width: sourceWidth,
        height: sourceHeight,
        name: options && options.name,
        crop: {
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight
        }
    }, {
        width: targetWidth,
        height: targetHeight,
        htmlTag: imageClientApi.htmlTag.IMG,
        alignment: imageClientApi.alignTypes.CENTER,
        pixelAspectRatio: devicePixelRatio
    }, options)

    return getURL(data.uri, options)
}

module.exports.populateGlobalFeatureSupport = imageClientApi.populateGlobalFeatureSupport
module.exports.getScaleToFitImageURL = getScaleToFitImageURL
module.exports.getScaleToFillImageURL = getScaleToFillImageURL
module.exports.getCropImageURL = getCropImageURL
